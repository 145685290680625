<template>
  <div>
    <div ref="$shopTheImageWrapper" class="relative">
      <MediaInterpreter :media="media" class="aspect-[718/900] w-full" />
      <Pin
        v-for="(position, positionIdx) in positions"
        :key="`${position.x}-${position.y}`"
        class="absolute"
        :style="{
          left: `${position.x * 100}%`,
          top: `${position.y * 100}%`,
        }"
        @click="handlePinClick(positionIdx)"
      />
    </div>

    <ProductCard
      ref="productCardRef"
      key="floating-product-card"
      v-on-click-outside="handlePinClickOutside"
      class="fixed z-10 transition-opacity"
      :style="{
        left: selectedProductWithPin?.product
          ? `${selectedProductPinPosition?.x}px`
          : '-500px',
        top: selectedProductWithPin?.product
          ? `${selectedProductPinPosition?.y}px`
          : '-500px',
        opacity: selectedProductWithPin?.product ? 1 : 0,
      }"
      :size="productCardSizes['NO_PHOTO']"
      :media-preview="selectedProductWithPin?.product?.cover?.media as Media"
      :title="
        productsMeta && selectedProductWithPin?.idx !== undefined
          ? productsMeta[selectedProductWithPin?.idx!]?.name
          : ''
      "
      :actual-unit-price="
        productsMeta && selectedProductWithPin?.idx !== undefined
          ? productsMeta[selectedProductWithPin?.idx]?.formattedPrice?.unitPrice
          : ''
      "
      :old-unit-price="
        productsMeta && selectedProductWithPin?.idx !== undefined
          ? productsMeta[selectedProductWithPin?.idx]?.formattedPrice?.listPrice
          : ''
      "
      :price="
        productsMeta && selectedProductWithPin?.idx !== undefined
          ? productsMeta[selectedProductWithPin?.idx]?.formattedPrice
              ?.readablePricePerUnit
          : ''
      "
      :formatted-product-route="
        productsMeta && selectedProductWithPin?.idx !== undefined
          ? productsMeta[selectedProductWithPin?.idx]?.formattedRoute
          : ''
      "
      label="Label"
      data-testid="product-box"
      @add-to-cart="$emit('addToCart', selectedProductWithPin?.product)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import type { Position } from "./ProductRecoShopTheImage.interface";
import type { Schemas } from "#shopware";
import type { Media } from "@shopware-pwa/types";
import ProductCard from "@/components/molecules/Card/ProductCard";
import Pin from "@/components/atoms/Pin";
import MediaInterpreter from "@/components/atoms/MediaInterpreter";
import {
  useElementBounding,
  useBreakpoints,
  useWindowSize,
  useCurrentElement,
} from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";
import { mediaBreakpointsResolver } from "@/constants/mediaBreakpointsResolver";
import { useRem } from "@/composables/useRem";
import { productCardSizes } from "@/constants/productCard";

const { rem } = useRem();

interface ProductMeta {
  name: string | null;
  formattedPrice: {
    unitPrice: string;
    listPrice: string;
    readablePricePerUnit: string;
  };
  formattedRoute: string | globalThis.RouteObject;
}

const props = defineProps<{
  products: Schemas["Product"][];
  productsMeta: ProductMeta[];
  positions: Position[];
  media: Media;
}>();

const { md: isBiggerThanMobile } = useBreakpoints(mediaBreakpointsResolver);

const productCardNoPhotoPadding = 16;
const productRecoShopTheImageSizes = {
  0: {
    maxWidth: 160 + 2 * productCardNoPhotoPadding,
  },
  [mediaBreakpointsResolver["md"]]: {
    maxWidth: 370,
  },
};

defineEmits(["addToCart"]);

const selectedProductWithPin = ref<{
  product: Schemas["Product"];
  idx: number;
} | null>(null);

const $shopTheImageWrapper = ref<HTMLElement | null>(null);
const productCardRef = ref();
const $floatingProductCard = useCurrentElement<HTMLElement>(productCardRef);
const { width: windowWidth } = useWindowSize();
const {
  left,
  top,
  width: wrapperWidth,
  height: wrapperHeight,
} = useElementBounding($shopTheImageWrapper);
const { height: floatingCardHeight, update: updateFloatingProductCard } =
  useElementBounding($floatingProductCard, {
    windowScroll: false,
  });
const selectedProductPinPosition = ref({ x: 0, y: 0 });

watch(selectedProductWithPin, async () => {
  await nextTick();
  updateFloatingProductCard();

  selectedProductPinPosition.value = getSelectedProductPinPosition();
});

watch([wrapperWidth, wrapperHeight, left, top], () => {
  updateFloatingProductCard();
  selectedProductPinPosition.value = getSelectedProductPinPosition();
});

const handlePinClick = (pinIdx: number) => {
  selectedProductWithPin.value = {
    product: props.products[pinIdx],
    idx: pinIdx,
  };
};

const handlePinClickOutside = () => {
  selectedProductWithPin.value = null;
};

const getSelectedProductPinPosition = () => {
  if (!selectedProductWithPin.value) return { x: 0, y: 0 };

  const remRatio = rem.value / 16;
  const productCardWidth =
    productRecoShopTheImageSizes[
      isBiggerThanMobile.value ? mediaBreakpointsResolver["md"] : 0
    ].maxWidth * remRatio;

  const VERTICAL_PADDING = rem.value / 4;

  const pinIdx = selectedProductWithPin.value.idx;

  const pinSize = rem.value * 2;

  let floatingCardLeft: number;
  let floatingCardTop: number;

  const computedPinLeftPosition =
    left.value + props.positions[pinIdx].x * wrapperWidth.value;
  const computedPinTopPosition =
    top.value + props.positions[pinIdx].y * wrapperHeight.value;

  if (isBiggerThanMobile.value) {
    floatingCardLeft = Math.floor(computedPinLeftPosition);

    floatingCardTop = Math.floor(
      computedPinTopPosition - floatingCardHeight.value - VERTICAL_PADDING * 2,
    );

    if (floatingCardLeft + productCardWidth > windowWidth.value) {
      floatingCardLeft -= productCardWidth - pinSize;
    }

    if (floatingCardTop - floatingCardHeight.value < 0) {
      floatingCardTop +=
        pinSize * 1.25 + floatingCardHeight.value + VERTICAL_PADDING * 2;
    }

    return {
      x: floatingCardLeft,
      y: floatingCardTop,
    };
  }

  floatingCardTop = Math.floor(
    computedPinTopPosition + pinSize / 2 - floatingCardHeight.value / 2,
  );

  floatingCardLeft = Math.floor(computedPinLeftPosition - productCardWidth);

  if (floatingCardLeft < 0) {
    floatingCardLeft = Math.floor(computedPinLeftPosition + pinSize);
  }

  return {
    x: floatingCardLeft,
    y: floatingCardTop,
  };
};
</script>
